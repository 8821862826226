* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.carousel {
    display: flex;
    justify-content: center;
}

.carousel .slide {
    height: 80vh;
}

.carousel .slide img {
    max-height: 100%;
    width: auto;
    object-fit: scale-down;
}

.gallery {
    margin-top: "1000px";
 }
/* .image {
    margin: 0 auto;
    height: 100%;
    width: auto;
} */
/*

.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
}

.image-container {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    height: "600px";  /* change this to whatever you want 

    width: 100%;
    object-fit: scale-down;
}
*/