@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
.social-container {
    background: #93E9BE;
    padding: 25px 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: auto;
  }

  a.social {
    margin: 0 1rem;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    display: inline-block;
  }

  a.social:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  button.twitter {
    color: #49a1eb;
    background-color: #93E9BE;
    border: none;
  }
  
  a.instagram {
    color: black;
  }

.fade-in-section {
    opacity: 0;
    -webkit-transform: translateY(20vh);
            transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
  
  /* Just for presentational purposes, please disregard in your own implementation */
  /* .App {
    max-width: 100%;
    padding: 1em;
  } */
  
  .box {
    padding: 50px;
    margin: 20px 0;
  }
  
  .box span {
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
  }
  
  html {
    box-sizing: border-box;
  }
  * {
    box-sizing: inherit;
  }
  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.carousel {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.carousel .slide {
    height: 80vh;
}

.carousel .slide img {
    max-height: 100%;
    width: auto;
    object-fit: scale-down;
}

.gallery {
    margin-top: "1000px";
 }
/* .image {
    margin: 0 auto;
    height: 100%;
    width: auto;
} */
/*

.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
}

.image-container {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    height: "600px";  /* change this to whatever you want 

    width: 100%;
    object-fit: scale-down;
}
*/
