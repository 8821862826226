.social-container {
    background: #93E9BE;
    padding: 25px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

  a.social:hover {
    transform: translateY(-2px);
  }

  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  button.twitter {
    color: #49a1eb;
    background-color: #93E9BE;
    border: none;
  }
  
  a.instagram {
    color: black;
  }